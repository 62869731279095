<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8">
        <template v-if="loaded">
          <v-card v-if="success">
            <v-card-text class="pa-8">
              <h2>Вітаємо із завершенням реєстрації у платформі WToo!</h2>
              <v-btn depressed small color="success" class="my-3"
                     :to="{ name: 'Login' }"
              >
                Авторизація
              </v-btn>
              <p class="mt-4" style="font-size: .94rem">
                <span class="font-weight-medium success--text">Wtoo</span> - це білінгова платформа для обліку нарахувань в ОСББ, ЖБК, ОСНБК, УК, ЖЕК, а також в інших організаціях які надають житлово-комунальні та комунальні послуги.
              </p>
              <div class="d-flex flex-wrap">
                <v-list width="100%">
                  <v-row>
                    <v-col cols="6">
                      <v-list-item class="grey lighten-3">
                        <v-list-item-icon>
                          <v-icon size="34">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="font-size: .97rem">
                          Поквартирних облік
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <v-list-item class="grey lighten-3">
                        <v-list-item-icon>
                          <v-icon size="34">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="font-size: .97rem">
                          Житлові та нежитлові приміщення
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <v-list-item class="grey lighten-3">
                        <v-list-item-icon>
                          <v-icon size="34">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="font-size: .97rem">
                          Монетизація пільг та субсидій
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <v-list-item class="grey lighten-3">
                        <v-list-item-icon>
                          <v-icon size="34">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="font-size: .97rem">
                          Облік квартирних та будинкових приладів обліку
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <v-list-item class="grey lighten-3">
                        <v-list-item-icon>
                          <v-icon size="34">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="font-size: .97rem">
                          Голосування, опитування та оповіщення
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="6">
                      <v-list-item class="grey lighten-3">
                        <v-list-item-icon>
                          <v-icon size="34">mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="font-size: .97rem">
                          Звіти та рахунки на оплату
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <v-card v-else>
            <v-card-text class="pa-12">
              <h3 class="text-center title">Вибачте але користувач вже активував свій обліковий запис</h3>
              <div class="d-flex justify-center mt-4">
                <v-btn depressed color="success" :to="{ name: 'Login' }">
                  Авторизація
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <div class="text-center mb-3 title">Зачекайте, будь ласка. Дані обробляються</div>
          <v-progress-linear
              indeterminate
              rounded
              height="24"
              color="success"
          ></v-progress-linear>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userAPI from '@/utils/axios/user'
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "UserActivate",
  data() {
    return {
      success: false,
      loaded: false
    }
  },
  methods: {
    activate_user() {
      const user_hash = this.$route.params.hash || ''
      if (user_hash) {
        userAPI.activate_user(user_hash)
          .then(response => response.data)
          .then(() => {
            this.success = true
          })
          .catch(err => {
            const error = err.response.data.detail;
            if (error !== 'Такого користувача не існує') {
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            }
          })
          .finally(() => {
            this.loaded = true
          })
      }
    }
  },
  created() {
    const $vm = this
    setTimeout(() => {
      $vm.activate_user()
    }, 2000)
  }
}
</script>

<style scoped>

</style>